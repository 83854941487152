import { render, staticRenderFns } from "./SetupStep.vue?vue&type=template&id=0642b87a&scoped=true&"
import script from "./SetupStep.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SetupStep.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SetupStep.vue?vue&type=style&index=0&id=0642b87a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0642b87a",
  null
  
)

export default component.exports