import { render, staticRenderFns } from "./ActiveCampaigns.vue?vue&type=template&id=a95b4cc4&scoped=true&"
import script from "./ActiveCampaigns.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ActiveCampaigns.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ActiveCampaigns.vue?vue&type=style&index=0&id=a95b4cc4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a95b4cc4",
  null
  
)

export default component.exports